import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { RoutingModule } from './routing';
import { ListadoComponent } from './listado/listado.component';
import { TopEnviosComponent } from './top-envios/top-envios.component';

@NgModule({
  declarations: [ListadoComponent, TopEnviosComponent],
  imports: [CommonModule, AuxiliaresModule, RoutingModule],
})
export class LogTwilioModule {}
