import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '../../../auxiliares/helper.service';
import { LoginService } from '../login.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public hide = true;
  public loading = false;
  public form: FormGroup = this.createForm();

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private helper: HelperService,
    private router: Router
  ) {}

  public async onSubmit() {
    this.loading = true;
    try {
      const username = this.form.get('username')?.value;
      const password = this.form.get('password')?.value;
      const token = await this.loginService.login(username, password);
      console.log(token);
      this.router.navigateByUrl('main/landing');
    } catch (error) {
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  private createForm() {
    return this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // const token = this.storeService.getToken();
    // if (token) {
    //   this.router.navigate(['/main']);
    // }
  }
}
