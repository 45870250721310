import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ICliente,
  IDispositivo,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarDispositivosComponent } from '../crear-editar-dispositivos/crear-editar-dispositivos.component';
import { DispositivosService } from '../dispositivos.service';

@Component({
  selector: 'app-listado-dispositivos',
  templateUrl: './listado-dispositivos.component.html',
  styleUrls: ['./listado-dispositivos.component.scss'],
})
export class ListadoDispositivosComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<IDispositivo>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'deveui',
    'deviceName',
    'appkey',
    'tipoDispositivo',
    'conectividad',
    'lote',
    'cliente',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'DevEUI',
    'Device Name',
    'Appkey',
    'Tipo',
    'Conectividad',
    'Lote',
    'Cliente',
    'Acciones',
  ];
  public saveColumnas = 'admin-gas-dispositivos';
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['deveui', 'deviceName'],
  };
  public tipo: IFiltroTabla = {
    elementos: [
      { nombre: 'NUC', _id: 'NUC' },
      { nombre: 'SML', _id: 'SML' },
      { nombre: 'NSP', _id: 'NSP' },
      { nombre: 'VERIBOX MICRO', _id: 'VERIBOX MICRO' },
    ],
    filter: {
      field: 'tipoDispositivo',
    },
    label: 'Tipo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
      value: '',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  // QUERY+
  private populate = [
    {
      path: 'cliente',
      select: 'nombre admin',
    },
    {
      path: 'lote',
      select: 'nombre',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'deveui',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public dispositivos$?: Subscription;
  public clientes$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: DispositivosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter<IDispositivo>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '800px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarDispositivosComponent, config);
  }

  public async editar(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '800px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarDispositivosComponent, config);
  }

  public async eliminar(dato: IDispositivo): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el dispositivo ${dato.deviceName}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id!).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.dispositivos$?.unsubscribe();
    this.dispositivos$ = this.listadosService
      .subscribe<IListado<IDispositivo>>('dispositivos', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de dispositivos`, data);
      });
    await this.listadosService.getLastValue('dispositivos', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar(), this.listarClientes()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.dispositivos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
