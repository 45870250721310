import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IQueryParam } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public pageSize = parseInt(localStorage.getItem('pageSize') || '15');
  public pageSizeOptions = [5, 10, 15, 25, 50, 100];

  constructor(private snackBar: MatSnackBar) {}

  public pageEvent($event: any): void {
    localStorage.setItem('pageSize', $event.pageSize);
    this.pageSize = $event.pageSize;
  }

  public getPageSize(nombreTabla?: string): number {
    const size = localStorage.getItem(nombreTabla || 'pageSize');
    return size ? +size : this.pageSizeOptions[4];
  }

  public static getQueryParams(queryParams?: IQueryParam) {
    let params = new HttpParams();
    if (queryParams) {
      const keysIgnorar = [
        'page',
        'limit',
        'sort',
        'populate',
        'select',
        'filter',
      ];
      if (queryParams?.page) {
        params = params.set('page', queryParams.page.toString());
      }
      if (queryParams?.limit) {
        params = params.set('limit', queryParams.limit.toString());
      }
      if (queryParams?.sort) {
        params = params.set('sort', queryParams.sort);
      }
      if (queryParams?.populate) {
        params = params.set('populate', queryParams.populate);
      }
      if (queryParams?.select) {
        params = params.set('select', queryParams.select);
      }
      if (queryParams?.filter) {
        params = params.set('filter', queryParams.filter);
      }

      for (const key in queryParams) {
        if (!keysIgnorar.includes(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return params;
  }

  // Notificaciones
  public notifError(error: any): void {
    let errorMessage = error.error?.message;
    if (!errorMessage) {
      const typeofError = typeof error.error;
      if (typeofError === 'string') {
        errorMessage = error.error;
      } else if (typeofError === 'object') {
        errorMessage = JSON.stringify(error.error);
      }
    }
    console.error(errorMessage);
    this.snackBar.open(errorMessage, 'Cerrar', {
      panelClass: ['red-snackbar'],
    });
  }
  public notifSuccess(mensaje: string): void {
    console.log(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: 3000,
      panelClass: ['green-snackbar'],
    });
  }
  public notifWarn(mensaje: string): void {
    console.log(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', { panelClass: ['yellow-snackbar'] });
  }
}
