<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" (click)="crear()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna Nombre -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nombre
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Config Lora Server -->
    <ng-container matColumnDef="loraServers">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Lora Servers
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <p style="margin: 0" *ngFor="let loraServer of row.loraServers">
            {{ loraServer.loraServer?.nombre || loraServer.idLoraServer }}
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna integrationUrl -->
    <ng-container matColumnDef="integrationUrl">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Integration URL
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.integrationUrl }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        style="overflow: visible"
        fxLayoutGap="5px"
      >
        <button
          mat-mini-fab
          matTooltip="Editar"
          color="accent"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator-goto>
</div>
