import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { Router } from '@angular/router';
import { LogoutService } from '../../login/logout.service';
import { IUsuario } from 'modelos/src';
import { LoginService } from '../../login/login.service';
import { version } from '../../../../environments/version';

type Envs = 'Local' | 'Dev' | 'Test' | 'Prod';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  public usuario?: IUsuario;

  public version = version;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private router: Router,
    private logoutService: LogoutService
  ) {}

  public logout() {
    this.dialogService
      .confirm('Confirme la acción', '¿Desea cerrar la sesión?')
      .then((confirm) => {
        if (confirm) {
          this.router.navigateByUrl('login');
          this.logoutService.logout();
        }
      });
  }

  async ngOnInit(): Promise<void> {
    this.usuario = LoginService.getUsuario();
  }
}
