import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IUsuario,
  ICreateUsuario,
  IUpdateUsuario,
  ICliente,
  Rol,
  IQueryParam,
  IListado,
  Nivel,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { UsuariosService } from '../usuarios.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-crear-editar-usuarios',
  templateUrl: './crear-editar-usuarios.component.html',
  styleUrls: ['./crear-editar-usuarios.component.scss'],
})
export class CrearEditarUsuariosComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;
  public hide = true;

  public clientes: ICliente[] = [];
  public roles: Rol[] = ['Administrador', 'Usuario', 'Visualizar'];
  public niveles: Nivel[] = ['Global'];

  get formArrayPermisos() {
    return this.form?.get('permisos') as FormArray;
  }

  // Listado Continuo
  public clientes$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUsuario,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarUsuariosComponent>,
    private service: UsuariosService,
    private helper: HelperService,
    private listadosService: ListadosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Usuario' : 'Crear Usuario';

    const formPermisos: FormGroup[] = [];

    if (this.data?.permisos?.length) {
      for (const permiso of this.data?.permisos) {
        formPermisos.push(
          this.fb.group({
            nivel: [permiso.nivel, Validators.required],
            rol: [permiso.rol, Validators.required],
            idsUnidadNegocios: [permiso.idsUnidadNegocios],
            idsCentroOperativos: [permiso.idsCentroOperativos],
            idsCuencas: [permiso.idsCuencas],
          })
        );
      }
    }

    this.form = this.fb.group({
      username: [this.data?.username, Validators.required],
      clave: ['', !this.data ? Validators.required : null],
      idCliente: [this.data?.idCliente, Validators.required],
      permisos: this.fb.array(formPermisos),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    const data: ICreateUsuario = this.form?.value;
    return data;
  }

  private getUpdateData() {
    const data: IUpdateUsuario = this.form?.value;
    // Clave
    delete data.clave;
    const clave = this.form?.get('clave')?.value;
    if (clave) {
      data.clave = clave;
    }
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public agregarPermiso() {
    this.formArrayPermisos.push(
      this.fb.group({
        nivel: [],
        rol: [null, Validators.required],
      })
    );
  }

  public eliminarPermiso(i: number) {
    this.formArrayPermisos.removeAt(i);
  }

  // Listados

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await this.listarClientes();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
  }
}
