import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  IFirmware,
  IUpdateFirmware,
  ICreateFirmware,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';
import { env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IFirmware>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/firmwares`, { params });
  }

  public crear(dato: ICreateFirmware): Observable<IFirmware> {
    return this.http.post(`/firmwares`, dato);
  }

  public listarPorId(id: string): Observable<IFirmware> {
    return this.http.get(`/firmwares/${id}`);
  }

  public editar(id: string, dato: IUpdateFirmware): Observable<IFirmware> {
    return this.http.put(`/firmwares/${id}`, dato);
  }

  public eliminar(id: string): Observable<IFirmware> {
    return this.http.delete(`/firmwares/${id}`);
  }

  public subirArchivo(file: File, nombre: string): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', nombre);
    return this.http.post(`/storage/uploadFirmwares`, formData);
  }
}
