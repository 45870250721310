<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<h1 style="margin: 2em; text-align: center">Bienvenido!!!</h1>
<h1 style="margin: 2em; text-align: center"></h1>

<!-- Detalles -->
<div fxLayout="row wrap">
  <ng-container *ngFor="let cliente of resumen">
    <div class="mat-elevation-z8" style="margin: 1em; padding: 0.5em" fxFlex>
      <h2>{{ cliente.cliente?.nombre }}</h2>
      <div style="padding: 0.5em">
        <h3>Dispositivos: {{ cliente.cantidadDispositivos }}</h3>
      </div>
    </div>
  </ng-container>
</div>
