<mat-card>
  <!-- <mat-card-subtitle> PLATAFORMA DE ADMINISTRACIÓN DE UTILITIES </mat-card-subtitle> -->
  <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    {{ title }}
  </mat-card-title>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form id="Form" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <!-- Firmware -->
      <mat-form-field>
        <mat-select
          placeholder="Firmwares"
          formControlName="idFirmware"
          required
        >
          <mat-option *ngFor="let dato of firmwares" [value]="dato._id">
            {{ dato.dispositivo }} | {{ dato.version }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Cliente -->
      <mat-form-field>
        <mat-select placeholder="Clientes" formControlName="idCliente" required>
          <mat-option *ngFor="let dato of clientes" [value]="dato._id">
            {{ dato.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="Form"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
