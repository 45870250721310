import { Component, OnInit } from '@angular/core';
import { IResumenCliente } from 'modelos/src';
import { first } from 'rxjs/operators';
import { ResumenService } from '../resumen.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public loading = false;
  public resumen: IResumenCliente[] = [];

  constructor(private resumenService: ResumenService) {}

  private async resumenClientes() {
    this.resumen = await this.resumenService
      .resumenClientes()
      .pipe(first())
      .toPromise();
    console.log('resumenClientes', this.resumen);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.resumenClientes();
    this.loading = false;
  }
}
