<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <div fxLayout="row" fxLayoutAlign="space-around">
    <!-- Envio SMS - 7 días-->
    <div style="width: 500px">
      <app-top-envios
        [logs]="logs7Dias"
        periodo="Últimos 7 días"
      ></app-top-envios>
    </div>

    <!-- Envio SMS - 8 Horas-->
    <div style="width: 500px">
      <app-top-envios
        [logs]="logs8Horas"
        periodo="Últimas 8 horas"
      ></app-top-envios>
    </div>
  </div>

  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[fecha, deveui]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna fecha -->
    <ng-container matColumnDef="fechaCreacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.fechaCreacion | date : "short" }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna telefono -->
    <ng-container matColumnDef="telefono">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Teléfono
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.telefono }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Mensaje -->
    <ng-container matColumnDef="mensaje">
      <mat-header-cell *matHeaderCellDef> Mensaje </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p>
          {{ row.mensaje }}
        </p>
      </mat-cell>
    </ng-container>

    <!-- Columna Respuesta -->
    <ng-container matColumnDef="respuesta">
      <mat-header-cell *matHeaderCellDef> Respuesta </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <p *ngIf="row.respuesta?.status">
            {{ row.respuesta.status }}
          </p>
          <p *ngIf="row.respuesta?.errorMessage">
            {{ row.respuesta.errorMessage }}
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna tiempoRespuesta -->
    <ng-container matColumnDef="tiempoRespuesta">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tiempo de Respuesta
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p>{{ row.tiempoRespuesta | number }} ms.</p>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator-goto>
</div>
