<mat-card class="my-2" style="max-width: 100%">
  <mat-card-header>
    <mat-card-title>Envíos de SMS</mat-card-title>
    <mat-card-subtitle>{{ periodo }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <mat-list style="max-height: 400px; overflow: auto">
      <ng-container *ngFor="let log of logs">
        <!-- <mat-list-item> -->

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayoutAlign="space-between center">
            <mat-icon matListItemIcon>sms</mat-icon>
            <span style="margin-left: 1em">{{ log.telefono }} </span>
          </div>
          <div>{{ log.cantidad }} SMS</div>
        </div>

        <!-- <button
          style="color: white"
          matListItemMeta
          mat-mini-fab
          color="accent"
          [routerLink]="
            '/main/configuracion/vecinos/' + evento.configVecino?._id
          "
        >
          <mat-icon>search</mat-icon>
        </button> -->
        <!-- </mat-list-item> -->
      </ng-container>
    </mat-list>
  </mat-card-content>

  <mat-card-content>
    <p class="label-primary">
      Total de SMS en el periodo:
      {{ total }}
    </p>
  </mat-card-content>
  <mat-card-footer *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
