import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IListado, ILogTwilio, IQueryParam } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class LogTwilioService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ILogTwilio>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/logtwilios`, { params });
  }

  public getGroupedByTelefono(
    params: IQueryParam
  ): Observable<{ telefono: string; envios: number }[]> {
    return this.http.get(`/logtwilios/getGroupedByTelefono`, {
      params,
    });
  }
}
