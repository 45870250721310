import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirmwareRoutingModule } from './firmware.routing.module';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListadoFirmwareComponent } from './listado-firmware/listado-firmware.component';
import { CrearEditarFirmwareComponent } from './crear-editar-firmware/crear-editar-firmware.component';
import { ListadoFirmwarePorClienteComponent } from './listado-firmware-por-cliente/listado-firmware-por-cliente.component';
import { CrearEditarFirmwarePorClienteComponent } from './crear-editar-firmware-por-cliente/crear-editar-firmware-por-cliente.component';
import { MatInputModule } from '@angular/material/input';
import { FirmwareComponent } from './firmwares/firmwares.component';
import { ListadoFirmwarePorDispositivoComponent } from './listado-firmware-por-dispositivo/listado-firmware-por-dispositivo.component';
import { CrearEditarFirmwarePorDispositivoComponent } from './crear-editar-firmware-por-dispositivo/crear-editar-firmware-por-dispositivo.component';

@NgModule({
  declarations: [
    FirmwareComponent,
    ListadoFirmwareComponent,
    CrearEditarFirmwareComponent,
    ListadoFirmwarePorClienteComponent,
    CrearEditarFirmwarePorClienteComponent,
    ListadoFirmwarePorDispositivoComponent,
    CrearEditarFirmwarePorDispositivoComponent,
  ],
  imports: [
    CommonModule,
    FirmwareRoutingModule,
    AuxiliaresModule,
    MatInputModule,
  ],
})
export class FirmwareModule {}
