import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoDispositivosComponent } from './listado-dispositivos/listado-dispositivos.component';
import { CrearEditarDispositivosComponent } from './crear-editar-dispositivos/crear-editar-dispositivos.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { DispositivosRoutingModule } from './dispositivos.routing.module';

@NgModule({
  declarations: [
    ListadoDispositivosComponent,
    CrearEditarDispositivosComponent,
  ],
  imports: [CommonModule, DispositivosRoutingModule, AuxiliaresModule],
})
export class DispositivosModule {}
