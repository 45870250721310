import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DialogService } from './dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  public promptEvent: any;

  constructor(
    private swUpdate: SwUpdate,
    private dialogService: DialogService
  ) {
    if (swUpdate.isEnabled) {
      // console.log('Service Worker Disponible');
      // Verifica si la app esta instalada
      window.addEventListener('beforeinstallprompt', (event) => {
        console.log('App no instalada');
        event.preventDefault();
        this.promptEvent = event;
      });
      // Verifica si hay nuevas versiones de la app cada X min
      const minutos = 1 * (1000 * 60);
      this.swUpdate.checkForUpdate();
      setInterval(() => {
        this.swUpdate.checkForUpdate();
      }, minutos);
      // Cuando hay una nueva version avisa para actualizar
      this.swUpdate.available.subscribe(() => {
        this.promptReaload();
      });
    } else {
      console.log('Service Worker No Disponible');
    }
  }

  private async promptReaload() {
    const confirm = await this.dialogService.confirm(
      'Nueva versión disponible',
      '¿Recargar ahora para aplicarla?'
    );
    if (confirm) {
      window.location.reload();
    }
  }

  public installPwa(): void {
    this.promptEvent.prompt();
    this.promptEvent.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        this.promptEvent = null;
      }
    });
  }
}
