import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter, IQueryParam, IListado, ILoraServer } from 'modelos/src';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';
import { LoraServersService } from '../lora-servers.service';
import { CrearEditarLoraServersComponent } from '../crear-editar-lora-servers/crear-editar-lora-servers.component';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla.component';

@Component({
  selector: 'app-listado-lora-servers',
  templateUrl: './listado-lora-servers.component.html',
  styleUrls: ['./listado-lora-servers.component.scss'],
})
export class ListadoLoraServersComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<ILoraServer>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'nombre',
    'tipo',
    'url',
    'integrationUrl',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'Nombre',
    'Tipo',
    'URL Server',
    'URL Integracion',
    'Acciones',
  ];
  public saveColumnas = 'admin-gas-lora-servers';
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'nombre',
  };

  // Listado Continuo
  public loraServers$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: LoraServersService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter<ILoraServer>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarLoraServersComponent, config);
  }

  public async editar(data: ILoraServer) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarLoraServersComponent, config);
  }

  public async eliminar(dato: ILoraServer): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar Lora Server ${dato.nombre}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id!).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.loraServers$?.unsubscribe();
    this.loraServers$ = this.listadosService
      .subscribe<IListado<ILoraServer>>('loraServers', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de loraServers`, data);
      });
    await this.listadosService.getLastValue('loraServers', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.loraServers$?.unsubscribe();
  }
}
