import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICreateFirmware,
  IFirmware,
  IUpdateFirmware,
  TipoDispositivoFirmware,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { FirmwareService } from '../firmware.service';

@Component({
  selector: 'app-crear-editar-firmware',
  templateUrl: './crear-editar-firmware.component.html',
  styleUrls: ['./crear-editar-firmware.component.scss'],
})
export class CrearEditarFirmwareComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;
  public nombreArchivo?: string = '';
  public dispositivos: TipoDispositivoFirmware[] = ['NSP', 'NUC'];
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement> | undefined;
  selectedFile: File | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFirmware,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarFirmwareComponent>,
    private helper: HelperService,
    private service: FirmwareService
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Firmware' : 'Crear Firmware';
    this.form = this.fb.group({
      version: [this.data?.version, Validators.required],
      dispositivo: [this.data?.dispositivo, Validators.required],
      archivo: [this.data?.archivo],
    });
    const archivo = this.data?.archivo?.split('/');
    if (archivo) this.nombreArchivo = archivo[archivo?.length - 1];
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  triggerFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      this.nombreArchivo = this.selectedFile.name;
    }
  }

  private getData(): ICreateFirmware | IUpdateFirmware {
    const data: ICreateFirmware | IUpdateFirmware = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    if (this.selectedFile) {
      this.loading = true;
      const url = await this.service
        .subirArchivo(this.selectedFile, this.selectedFile.name)
        .pipe(first())
        .toPromise();
      this.form?.patchValue({ archivo: url.url });
    } else {
      if (!this.data.archivo) {
        this.helper.notifWarn('Se debe seleccionar un archivo');
        return;
      }
    }
    try {
      const data = this.getData();
      console.log(data);
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
  }
}
