import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IDispositivo,
  ICreateDispositivo,
  IListado,
  IQueryParam,
  IUpdateDispositivo,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class DispositivosService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IDispositivo>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/dispositivos`, { params });
  }

  public crear(dato: ICreateDispositivo): Observable<IDispositivo> {
    return this.http.post(`/dispositivos`, dato);
  }

  public listarPorId(id: string): Observable<IDispositivo> {
    return this.http.get(`/dispositivos/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateDispositivo
  ): Observable<IDispositivo> {
    return this.http.put(`/dispositivos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/dispositivos/${id}`);
  }
}
