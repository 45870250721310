import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IUsuario,
  IFilter,
  IQueryParam,
  IListado,
  ICliente,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { UsuariosService } from '../usuarios.service';
import { CrearEditarUsuariosComponent } from '../crear-editar-usuarios/crear-editar-usuarios.component';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-listado-usuarios',
  templateUrl: './listado-usuarios.component.html',
  styleUrls: ['./listado-usuarios.component.scss'],
})
export class ListadoUsuariosComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<IUsuario>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'username',
    'cliente',
    'activo',
    'permisos',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'Username',
    'Cliente',
    'Activo',
    'Permisos',
    'Acciones',
  ];
  public saveColumnas = 'admin-gas-usuarios';
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['username'],
  };
  public estado: IFiltroTabla = {
    elementos: [
      { nombre: 'Activos', _id: 'true' },
      { nombre: 'Inactivos', _id: 'false' },
    ],
    filter: {
      field: 'activo',
      value: true,
    },
    label: 'Estado',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
      value: '',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  // QUERY+
  private filter: IFilter<IUsuario> = {
    activo: true,
  };
  private populate = [
    {
      path: 'cliente',
      select: 'nombre admin',
    },
    {
      path: 'permisos.centroOperativos',
      select: 'nombre',
    },
    {
      path: 'permisos.unidadNegocios',
      select: 'nombre',
    },
    {
      path: 'permisos.cuencas',
      select: 'nombre',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'nombre',
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public usuarios$?: Subscription;
  public clientes$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: UsuariosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter<IUsuario>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarUsuariosComponent, config);
  }

  public async editar(data: IUsuario) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarUsuariosComponent, config);
  }

  public async eliminar(dato: IUsuario): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el usuario ${dato.username}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id!).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listadosService
      .subscribe<IListado<IUsuario>>('usuarios', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listadosService.getLastValue('usuarios', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar(), this.listarClientes()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.usuarios$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
