<mat-card>
  <!-- <mat-card-subtitle> PLATAFORMA DE ADMINISTRACIÓN DE UTILITIES </mat-card-subtitle> -->
  <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    {{ title }}
  </mat-card-title>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form id="Form" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <!-- Firmware -->
      <mat-form-field>
        <mat-select
          placeholder="Firmwares"
          formControlName="idFirmware"
          required
        >
          <mat-option *ngFor="let dato of firmwares" [value]="dato._id">
            {{ dato.dispositivo }} | {{ dato.version }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Dispositivo -->

      <div fxLayout="row">
        <ng-select
          fxFlex
          [items]="dispositivos$ | async"
          [typeahead]="inputDispositivos$"
          [loading]="loading"
          [minTermLength]="3"
          bindLabel="deviceName"
          bindValue="_id"
          formControlName="idDispositivo"
          loadingText="Cargando..."
          typeToSearchText="Buscar por Nombre"
          notFoundText="No se encontraron dispositivos"
          appendTo="body"
          [placeholder]="'Dispositivo: '"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <span>{{ item.deviceName }} </span>
            <span style="font-size: 1em"> ({{ item.deveui }})</span>
          </ng-template>

          <ng-template
            ng-label-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <span>{{ item.deviceName }} </span>
            <span style="font-size: 1em"> ({{ item.deveui }})</span>
          </ng-template>
        </ng-select>
      </div>
      <!--
      <mat-form-field>
        <mat-select
          placeholder="Dispositivos"
          formControlName="idDispositivo"
          required
        >
          <mat-option *ngFor="let dato of dispositivos" [value]="dato._id">
            {{ dato.deviceName }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="Form"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
