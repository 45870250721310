import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter, IQueryParam, IListado, ILogTwilio } from 'modelos/src';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
})
export class ListadoComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<ILogTwilio>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'fechaCreacion',
    'telefono',
    'mensaje',
    'respuesta',
  ];
  public nombreColumnas: string[] = [
    'Fecha',
    'Teléfono',
    'Mensaje',
    'Respuesta',
  ];
  public saveColumnas = 'admin-gas-log-twilio';
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['telefono', 'device.deveui'],
  };

  public fecha: IFiltroTabla = {
    filter: {
      field: 'fechaCreacion',
    },
    label: 'Fecha',
    tipo: 'dateRange',
  };
  public deveui: IFiltroTabla = {
    filter: {
      field: 'device.deveui',
    },
    label: 'Deveui',
    tipo: 'input',
  };

  //
  public logs7Dias?: { telefono: string; cantidad: number }[];
  public logs8Horas?: { telefono: string; cantidad: number }[];

  // QUERY+
  private filter: IFilter<ILogTwilio> = {};
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fechaCreacion',
    filter: JSON.stringify(this.filter),
  };

  // Listado Continuo
  public datos$?: Subscription;
  public datos2$?: Subscription;
  public datos3$?: Subscription;

  constructor(
    // private service: LogTwilioService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter<ILogTwilio>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ILogTwilio>>('logTwilios', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de logTwilios`, data);
      });
    await this.listadosService.getLastValue('logTwilios', this.queryParams);
  }

  private async listarGroupedByTel7Days(): Promise<void> {
    const desde = new Date();
    desde.setDate(desde.getDate() - 7);

    const query: IQueryParam = {
      desde: desde.toISOString(),
    };

    this.datos2$?.unsubscribe();
    this.datos2$ = this.listadosService
      .subscribe<{ telefono: string; cantidad: number }[]>(
        'logTwiliosGroupedByTelefono',
        query
      )
      .subscribe((data) => {
        console.log(`listado de logTwiliosGroupedByTelefono`, data);
        this.logs7Dias = data;
      });
    await this.listadosService.getLastValue(
      'logTwiliosGroupedByTelefono',
      query
    );
  }

  private async listarGroupedByTel8Hours(): Promise<void> {
    const desde = new Date();
    desde.setHours(desde.getHours() - 8);

    const query: IQueryParam = {
      desde: desde.toISOString(),
    };

    this.datos3$?.unsubscribe();
    this.datos3$ = this.listadosService
      .subscribe<{ telefono: string; cantidad: number }[]>(
        'logTwiliosGroupedByTelefono',
        query
      )
      .subscribe((data) => {
        console.log(`listado de logTwiliosGroupedByTelefono`, data);
        this.logs8Horas = data;
      });
    await this.listadosService.getLastValue(
      'logTwiliosGroupedByTelefono',
      query
    );
  }

  // Body

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([
      this.listar(),
      this.listarGroupedByTel7Days(),
      this.listarGroupedByTel8Hours(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.datos2$?.unsubscribe();
    this.datos3$?.unsubscribe();
  }
}
