import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-envios',
  templateUrl: 'top-envios.component.html',
  styleUrls: ['./top-envios.component.scss'],
})
export class TopEnviosComponent implements OnInit {
  @Input() logs?: { telefono: string; cantidad: number }[];
  @Input() loading = false;
  @Input() periodo?: string;
  public total?: number;

  constructor() {}

  async ngOnInit() {}

  ngOnChanges(): void {
    if (this.logs?.length) {
      this.total = this.logs.reduce((acc, log) => acc + log.cantidad, 0);
    } else {
      this.total = 0;
    }
  }
}
