import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICliente,
  ICreateCliente,
  IIntegracion,
  IIntegracionInfluxV1,
  IIntegracionInfluxV2,
  IListado,
  IQueryParam,
  ITipoDispositivo,
  TipoDispositivo,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ClientesService } from '../clientes.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-crear-editar-clientes',
  templateUrl: './crear-editar-clientes.component.html',
  styleUrls: ['./crear-editar-clientes.component.scss'],
})
export class CrearEditarClientesComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  get formArrayIntegraciones() {
    return this.form?.get('integraciones') as FormArray;
  }

  public formCredenciales(i: number) {
    return this.formArrayIntegraciones.at(i).get('credenciales') as FormGroup;
  }

  public formArrayCredenciales2(i: number) {
    return this.formArrayIntegraciones.at(i).get('credenciales2') as FormArray;
  }

  public tipoDispositivos: TipoDispositivo[] = [];
  public tipoIntegraciones = ['INFLUXV1', 'INFLUXV2', 'HTTPS', 'FTP'];
  public metodosHttp = ['POST', 'PUT', 'PATCH', 'GET'];
  public ubicacionesCredenciales = ['Query Params', 'Headers', 'Body'];

  // Listado Continuo
  public tipoDispositivos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICliente,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarClientesComponent>,
    private helper: HelperService,
    private service: ClientesService,
    private listadosService: ListadosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Cliente' : 'Crear Cliente';

    const imagenes = this.fb.group({
      logo: [this.data?.imagenes?.logo],
    });

    const formIntegraciones: FormGroup[] = [];
    if (this.data?.integraciones?.length) {
      for (const integracion of this.data?.integraciones) {
        let formCredenciales: FormGroup = this.fb.group({});
        if (integracion.tipoIntegracion === 'INFLUXV1') {
          const credenciales = integracion.credenciales as IIntegracionInfluxV1;
          formCredenciales = this.fb.group({
            host: [credenciales.host],
            port: [credenciales.port],
            protocol: [credenciales.protocol],
            username: [credenciales.username],
            password: [credenciales.password],
            dbName: [credenciales.dbName],
            measurement: [credenciales.measurement],
          });
        } else if (integracion.tipoIntegracion === 'INFLUXV2') {
          const credenciales = integracion.credenciales as IIntegracionInfluxV2;

          formCredenciales = this.fb.group({
            host: [credenciales.host],
            port: [credenciales.port],
            protocol: [credenciales.protocol],
            token: [credenciales.token],
            dbName: [credenciales.dbName],
            measurement: [credenciales.measurement],
            org: [credenciales.org],
          });
        }

        let formCredenciales2: FormGroup[] = [];
        if (integracion.credenciales2) {
          for (const credencial2 of integracion.credenciales2) {
            formCredenciales2.push(
              this.fb.group({
                key: [credencial2.key],
                value: [credencial2.value],
              })
            );
          }
        }

        formIntegraciones.push(
          this.fb.group({
            tipoDispositivo: [integracion.tipoDispositivo],
            tipoIntegracion: [integracion.tipoIntegracion],
            endpoint: [integracion.endpoint],
            puerto: [integracion.puerto || '21'],
            method: [integracion.method],
            ubicacionCredenciales: [integracion.ubicacionCredenciales],
            credenciales: formCredenciales,
            credenciales2: this.fb.array(formCredenciales2),
          })
        );
      }
    }

    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      tiposDispositivo: [this.data?.tiposDispositivo],
      imagenes,
      integraciones: this.fb.array(formIntegraciones),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateCliente = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public cambioTipoIntegracion(i: number) {
    const tipoIntegracion = this.formArrayIntegraciones
      .at(i)
      .get('tipoIntegracion')?.value;

    let formCredenciales: FormGroup = this.fb.group({});

    if (tipoIntegracion === 'INFLUXV1') {
      formCredenciales = this.fb.group({
        host: [null],
        port: [null],
        protocol: [null],
        username: [null],
        password: [null],
        dbName: [null],
        measurement: [null],
      });
    } else if (tipoIntegracion === 'INFLUXV2') {
      formCredenciales = this.fb.group({
        host: [null],
        port: [null],
        protocol: [null],
        token: [null],
        dbName: [null],
        measurement: [null],
        org: [null],
      });
    }

    (this.formArrayIntegraciones.at(i) as FormGroup)?.setControl(
      'credenciales',
      formCredenciales
    );
  }

  public agregarIntegracion() {
    this.formArrayIntegraciones.push(
      this.fb.group({
        tipoDispositivo: [null],
        tipoIntegracion: [null],
        endpoint: [null],
        puerto: [21],
        ubicacionCredenciales: [null],
        method: [null],
        // credenciales: formCredenciales,
        credenciales2: this.fb.array([
          this.fb.group({
            key: [null],
            value: [null],
          }),
        ]),
      })
    );
  }

  public eliminarIntegracion(i: number) {
    this.formArrayIntegraciones.removeAt(i);
  }

  public agregarCredenciales2(i: number) {
    this.formArrayCredenciales2(i).push(
      this.fb.group({
        key: [null],
        value: [null],
      })
    );
  }

  public eliminarCredenciales2(i: number, j: number) {
    this.formArrayCredenciales2(i).removeAt(j);
  }

  //

  private async listarTipoDispositivos(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
    };
    this.tipoDispositivos$?.unsubscribe();
    this.tipoDispositivos$ = this.listadosService
      .subscribe<IListado<ITipoDispositivo>>('tipoDispositivos', query)
      .subscribe((data) => {
        this.tipoDispositivos = data.datos.map((e) => e.nombre!);
        console.log(`listado de tipoDispositivos`, data);
      });
    await this.listadosService.getLastValue('tipoDispositivos', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await this.listarTipoDispositivos();
    this.loading = false;
  }
}
