import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @ViewChild('lineCounter') lineCounter?: HTMLTextAreaElement;
  @ViewChild('textArea') textArea?: HTMLTextAreaElement;

  @Input() form?: FormGroup;
  @Input() controlName: string = '';

  @Input() ejemplo = '';
  @Input() formato = '';
  @Input() rows = 5;

  public lineCountCache = 0;
  public lineCounterValue = '1.';

  constructor() {}

  public setLineas(lineCount: number) {
    const outarr = new Array();
    if (this.lineCountCache != lineCount) {
      for (var x = 0; x < lineCount; x++) {
        outarr[x] = x + 1 + '.';
      }
      this.lineCounterValue = outarr.join('\n');
    }
    this.lineCountCache = lineCount;
  }

  public getErrorMessage() {
    if (this.form?.get(this.controlName)?.hasError('error')) {
      return this.form?.get(this.controlName)?.getError('error');
    }
    return;
  }

  public eliminarLineaDelTextArea(linea: string) {
    const input = this.form?.get(this.controlName)?.value;
    if (input) {
      const lineas = input.split('\n');
      const index = lineas.indexOf(linea);
      if (index > -1) {
        lineas.splice(index, 1);
        this.form?.patchValue({ [this.controlName]: lineas.join('\n') });
      }
      this.onInput();
    }
  }

  public scrollTop() {
    const textArea = document.getElementById('textArea');
    if (textArea) {
      textArea.scrollTop = 0;
    }
  }

  public onScroll() {
    const textArea = document.getElementById('textArea');
    const lineCounter = document.getElementById('lineCounter');
    if (textArea && lineCounter) {
      lineCounter.scrollTop = textArea.scrollTop;
      lineCounter.scrollLeft = textArea.scrollLeft;
    }
  }

  public onInput() {
    const input = this.form?.get(this.controlName)?.value;
    const lineCount = input.split('\n').length || 0;
    this.setLineas(lineCount);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {}
}
