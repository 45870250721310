import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Rol } from 'modelos/src';
import { LoginService } from '../../modulos/login/login.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class RolesGlobalesGuard implements CanActivate {
  constructor(private router: Router, private helper: HelperService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const rolesPermitidos = route.data.roles as Rol[];
    const rolesUsuario =
      LoginService.getUsuario().permisos?.map((p) => p.rol) || [];
    if (
      !rolesPermitidos?.length ||
      rolesPermitidos.some((rol) => rolesUsuario.includes(rol))
    ) {
      return true;
    } else {
      this.helper.notifWarn('Permiso de rol denegado');
      return this.router.parseUrl('/main');
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    const rolesPermitidos = route.data.roles as Rol[];
    const rolesUsuario =
      LoginService.getUsuario().permisos?.map((p) => p.rol) || [];
    if (
      !rolesPermitidos?.length ||
      rolesPermitidos.some((rol) => rolesUsuario.includes(rol))
    ) {
      return true;
    } else {
      this.helper.notifWarn('Permiso de rol denegado');
      return this.router.parseUrl('/main');
    }
  }
}
