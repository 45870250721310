import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IFilter,
  IQueryParam,
  IListado,
  ILoteDispositivo,
  IFirmware,
  TipoDispositivoFirmware,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';
import { FirmwareService } from '../firmware.service';
import { CrearEditarFirmwareComponent } from '../crear-editar-firmware/crear-editar-firmware.component';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla.component';

@Component({
  selector: 'app-listado-firmware',
  templateUrl: './listado-firmware.component.html',
  styleUrls: ['./listado-firmware.component.scss'],
})
export class ListadoFirmwareComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<IFirmware>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'fechaCreacion',
    'version',
    'dispositivo',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'Fecha',
    'Version',
    'Dispositivo',
    'Acciones',
  ];
  public saveColumnas = 'admin-gas-firmware';
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['version'],
  };
  /*public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
  };*/

  public filter: IFilter<IFirmware> = {};
  public queryParams: IQueryParam = {
    filter: JSON.stringify(this.filter),
    sort: '-fechaCreacion',
  };

  // Listado Continuo
  public firmwares$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: FirmwareService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter<IFirmware>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarFirmwareComponent, config);
  }

  public async editar(data: IFirmware) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarFirmwareComponent, config);
  }

  public async eliminar(dato: IFirmware): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el firmware ${dato.version} de ${dato.dispositivo}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id!).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.firmwares$?.unsubscribe();
    this.firmwares$ = this.listadosService
      .subscribe<IListado<IFirmware>>('firmwares', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de firmwares`, data);
      });
    await this.listadosService.getLastValue('firmwares', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.firmwares$?.unsubscribe();
  }
}
