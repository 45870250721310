import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  IFirmwarePorEntidad,
  ICreateFirmwarePorEntidad,
  IUpdateFirmwarePorEntidad,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class FirmwarePorEntidadService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<IFirmwarePorEntidad>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/firmwaresporentidad`, { params });
  }

  public crear(
    dato: ICreateFirmwarePorEntidad
  ): Observable<IFirmwarePorEntidad> {
    console.log('si');
    console.log(dato);
    return this.http.post(`/firmwaresporentidad`, dato);
  }

  public listarPorId(id: string): Observable<IFirmwarePorEntidad> {
    return this.http.get(`/firmwaresporentidad/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateFirmwarePorEntidad
  ): Observable<IFirmwarePorEntidad> {
    return this.http.put(`/firmwaresporentidad/${id}`, dato);
  }

  public eliminar(id: string): Observable<IFirmwarePorEntidad> {
    return this.http.delete(`/firmwaresporentidad/${id}`);
  }
}
