import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILogNuc } from 'modelos/src';

@Component({
  selector: 'app-mostrarBody',
  templateUrl: './mostrarBody.component.html',
  styleUrls: ['./mostrarBody.component.scss'],
})
export class MostrarBodyComponent implements OnInit {
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILogNuc,
    private dialogRef: MatDialogRef<MostrarBodyComponent>
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.loading = false;
  }
}
