<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[tipoMensaje, fecha, deveui]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna fecha -->
    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.fecha | date : "short" }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna deveui -->
    <ng-container matColumnDef="deveui">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Deveui
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.deveui }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna tipo -->
    <ng-container matColumnDef="tipo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tipo de Mensaje
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p [style.color]="row.color">
          {{ row.tipo }}
        </p>
      </mat-cell>
    </ng-container>

    <!-- Columna codigoRespuesta -->
    <ng-container matColumnDef="codigoRespuesta">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Código de Respuesta
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p>
          {{ row.codigoRespuesta }}
        </p>
      </mat-cell>
    </ng-container>

    <!-- Columna tiempoRespuesta -->
    <ng-container matColumnDef="tiempoRespuesta">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tiempo de Respuesta
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p>{{ row.tiempoRespuesta | number }} ms.</p>
      </mat-cell>
    </ng-container>

    <!-- Columna body -->
    <ng-container matColumnDef="body">
      <mat-header-cell *matHeaderCellDef> Body </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="10px">
          <button
            *ngIf="row.body"
            mat-raised-button
            (click)="mostrarBody(row.body)"
            color="accent"
          >
            Req
          </button>
          <button
            *ngIf="row.respuesta"
            mat-raised-button
            (click)="mostrarBody(row.respuesta)"
            color="accent"
          >
            Res
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>

  <!-- Paginator -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>
</div>
