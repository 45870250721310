<!-- <mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar> -->

<div>
  <div style="display: flex; justify-content: space-evenly">
    <button
      id="firmware-boton"
      style="width: 33%; height: 50px; background-color: #404040; color: white"
      (click)="onFirmware()"
    >
      Firmware
    </button>
    <button
      id="firmware-por-cliente-boton"
      style="width: 33%; height: 50px; background-color: #404040; color: white"
      (click)="onFirmwarePorCliente()"
    >
      Firmware por Cliente
    </button>
    <button
      id="firmware-por-dispositivo-boton"
      style="width: 33%; height: 50px; background-color: #404040; color: white"
      (click)="onFirmwarePorDispositivo()"
    >
      Firmware por Dispositivo
    </button>
  </div>
  <div id="firmware">
    <app-listado-firmware></app-listado-firmware>
  </div>
  <div id="firmware-por-cliente" style="display: none">
    <app-listado-firmware-por-cliente></app-listado-firmware-por-cliente>
  </div>
  <div id="firmware-por-dispositivo" style="display: none">
    <app-listado-firmware-por-dispositivo></app-listado-firmware-por-dispositivo>
  </div>
</div>
