import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IDispositivo,
  ICreateFirmwarePorEntidad,
  IFilter,
  IFirmware,
  IFirmwarePorEntidad,
  IListado,
  IQueryParam,
  IUpdateFirmwarePorEntidad,
} from 'modelos/src';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  switchMap,
} from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { Observable, of, Subject, Subscription } from 'rxjs';

import { FirmwarePorEntidadService } from '../firmware-por-entidad.service';

@Component({
  selector: 'app-crear-editar-firmware-por-dispositivo',
  templateUrl: './crear-editar-firmware-por-dispositivo.component.html',
  styleUrls: ['./crear-editar-firmware-por-dispositivo.component.scss'],
})
export class CrearEditarFirmwarePorDispositivoComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public firmwares?: IFirmware[] = [];
  private firmwares$?: Subscription;

  public dispositivos?: IDispositivo[] = [];
  public dispositivos$?: Observable<IDispositivo[]>;
  inputDispositivos$ = new Subject<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFirmwarePorEntidad,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarFirmwarePorDispositivoComponent>,
    private helper: HelperService,
    private service: FirmwarePorEntidadService,
    private listadosService: ListadosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Firmware por Dispositivo'
      : 'Crear Firmware por Dispositivo';
    this.form = this.fb.group({
      idDispositivo: [this.data?.idDispositivo, Validators.required],
      idFirmware: [this.data?.idFirmware, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  private async listarFirmwares(): Promise<void> {
    const filter: IFilter<IFirmware> = {};
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: '-fechaCreacion',
    };
    this.firmwares$?.unsubscribe();
    this.firmwares$ = this.listadosService
      .subscribe<IListado<IFirmware>>('firmwares', query)
      .subscribe((data) => {
        this.firmwares = data.datos;
        console.log(`listado de firmwares`, data);
      });
    await this.listadosService.getLastValue('firmwares', query);
  }

  /*private async listarDispositivos(): Promise<void> {
    const filter: IFilter<IDispositivo> = {};
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
    };
    this.dispositivos$?.unsubscribe();
    this.dispositivos$ = this.listadosService
      .subscribe<IListado<IDispositivo>>('dispositivos', query)
      .subscribe((data) => {
        this.dispositivos = data.datos;
        console.log(`listado de dispositivos`, data);
      });
    await this.listadosService.getLastValue('dispositivos', query);
  }*/
  //

  private crearFiltroDispositivo(search: string): IQueryParam {
    const filtro: IFilter<IDispositivo> = {};
    if (search) {
      filtro.$or = [
        { deviceName: { $regex: search, $options: 'i' } },
        { deveui: { $regex: search, $options: 'i' } },
      ] as never;
    }

    const query: IQueryParam = {
      filter: JSON.stringify(filtro),
      limit: 10,
      select: 'deviceName deveui',
    };
    return query;
  }

  private onSearchDispositivos() {
    this.dispositivos$ = of([]);
    this.inputDispositivos$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filter((term) => term?.length > 2),
        switchMap((term) => {
          this.loading = true;
          return this.listadosService.listarDispositivos$(
            this.crearFiltroDispositivo(term)
          );
        })
      )
      .subscribe((data) => {
        console.log('vecinos encontrados', data);
        this.dispositivos = data;
        this.dispositivos$ = of(data);
        this.loading = false;
      });
  }

  private getData(): ICreateFirmwarePorEntidad | IUpdateFirmwarePorEntidad {
    const data: ICreateFirmwarePorEntidad | IUpdateFirmwarePorEntidad =
      this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      const firm = this.firmwares?.find((f) => f._id === data.idFirmware);
      data.tipo = firm?.dispositivo;
      data.version = firm?.version;
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.onSearchDispositivos();
    await Promise.all([this.listarFirmwares()]); //, this.listarDispositivos()]);
  }
}
