import { Injectable } from '@angular/core';
import { IResumenCliente } from 'modelos/src';
import { Observable } from 'rxjs';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ResumenService {
  constructor(private http: HttpService) {}

  public resumenClientes(): Observable<IResumenCliente[]> {
    return this.http.get(`/clientes/resumen`);
  }
}
