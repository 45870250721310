<mat-card>
  <mat-card-header style="align-items: center">
    <mat-card-title>Body</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <pre>{{ data | json }}</pre>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>
</mat-card>
