import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter, IQueryParam, IListado, ILogNuc } from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';
import { UsuariosService } from '../../usuarios/usuarios.service';
import { MostrarBodyComponent } from './mostrarBody/mostrarBody.component';

interface ILogTabla extends ILogNuc {
  color: string;
}

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
})
export class ListadoComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<ILogTabla>([]);
  public totalCount = 0;
  // public columnas: string[] = [];
  // public nombreColumnas: string[] = [];
  public columnas: string[] = [
    'fecha',
    'deveui',
    'tipo',
    'tiempoRespuesta',
    'codigoRespuesta',
    'body',
  ];
  public nombreColumnas: string[] = [
    'Fecha',
    'DevEUI',
    'Tipo de Mensaje',
    'Tiempo de Respuesta',
    'Codigo de Respuesta',
    'Body',
  ];
  public saveColumnas = 'admin-gas-log-nucs';
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['deveui'],
  };
  public tipoMensaje: IFiltroTabla = {
    elementos: [
      { nombre: 'ISetAlerta', _id: 'ISetAlerta' },
      { nombre: 'ISetAlertaV2', _id: 'ISetAlertaV2' },
      { nombre: 'ISetConfiguracion', _id: 'ISetConfiguracion' },
      { nombre: 'ISetConfiguracionV2', _id: 'ISetConfiguracionV2' },
      { nombre: 'ISetCorrectora', _id: 'ISetCorrectora' },
      { nombre: 'ISetCorrectoraV3', _id: 'ISetCorrectoraV3' },
      { nombre: 'ISetCromatografia', _id: 'ISetCromatografia' },
      { nombre: 'ISetRegistro', _id: 'ISetRegistro' },
      { nombre: 'ISetRegistroV3', _id: 'ISetRegistroV3' },
      { nombre: 'ISetReporte', _id: 'ISetReporte' },
      { nombre: 'ISetReporteV3', _id: 'ISetReporteV3' },
      { nombre: 'IGetConfiguracionV2', _id: 'IGetConfiguracionV2' },
      { nombre: 'IGetCromatografia', _id: 'IGetCromatografia' },
      { nombre: 'IGetRegistro', _id: 'IGetRegistro' },
    ],
    filter: {
      field: 'tipo',
    },
    label: 'Tipo de Mensaje',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public fecha: IFiltroTabla = {
    filter: {
      field: 'fecha',
    },
    label: 'Fecha',
    tipo: 'dateRange',
  };
  public deveui: IFiltroTabla = {
    filter: {
      field: 'deveui',
    },
    label: 'Deveui',
    tipo: 'input',
  };

  // QUERY+
  private filter: IFilter<ILogNuc> = {};
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
    filter: JSON.stringify(this.filter),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: UsuariosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter<ILogNuc>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ILogNuc>>('logNucs', this.queryParams)
      .subscribe((data) => {
        const logs = data.datos as ILogTabla[];
        logs.forEach((log) => {
          log.color = this.getRandomColorFromString(log.tipo!);
        });

        this.totalCount = data.totalCount;
        this.dataSource.data = logs;
        console.log(`listado de logNucs`, data);
      });
    await this.listadosService.getLastValue('logNucs', this.queryParams);
  }

  // Body

  public async mostrarBody(data: ILogNuc) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      // disableClose: true,
    };
    this.matDialog.open(MostrarBodyComponent, config);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }

  // Colores by Chat-GPT
  public getRandomColorFromString(str: string): string {
    let hash = 0;
    str = str + 'random2077';
    // Generate a hash code from the string
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash code to a hex color code
    const hex = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - hex.length) + hex;
  }
}
