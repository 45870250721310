<mat-card>
  <!-- <mat-card-subtitle> PLATAFORMA DE ADMINISTRACIÓN DE UTILITIES </mat-card-subtitle> -->
  <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    {{ title }}
  </mat-card-title>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form id="loginForm" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <!-- Version -->
      <mat-form-field>
        <mat-label>Version</mat-label>
        <input
          matInput
          placeholder="Version"
          formControlName="version"
          required
        />
      </mat-form-field>

      <!-- Dispositivo -->
      <mat-form-field>
        <mat-select placeholder="Dispositivo" formControlName="dispositivo" required>
          <mat-option *ngFor="let dato of dispositivos" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button type="button" (click)="triggerFileInput()">Seleccionar archivo</button>
      <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />
        <mat-label style="align-self: center;">{{ nombreArchivo }}</mat-label>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
