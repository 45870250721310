<mat-card>
  <!-- <mat-card-subtitle> PLATAFORMA DE ADMINISTRACIÓN DE UTILITIES </mat-card-subtitle> -->
  <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    {{ title }}
  </mat-card-title>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form id="loginForm" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <!-- Nombre  -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <!-- Tipo -->
      <mat-form-field>
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="tipo" required>
          <mat-option *ngFor="let dato of tiposLoraServer" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- URL  -->
      <mat-form-field>
        <mat-label>URL Server</mat-label>
        <input
          matInput
          placeholder="URL Server"
          formControlName="url"
          required
          (change)="cambioUrl()"
        />
      </mat-form-field>

      <!-- Integration URL  -->
      <mat-form-field>
        <mat-label>Integration URL</mat-label>
        <input
          matInput
          placeholder="Integration URL"
          formControlName="integrationUrl"
        />
      </mat-form-field>

      <!-- Chirpstack -->
      <!-- Token  -->
      <mat-form-field *ngIf="form?.get('tipo')?.value === 'ChirpStack'">
        <mat-label>Token ChirpStack</mat-label>
        <input
          matInput
          placeholder="Token ChirpStack"
          formControlName="token"
          (change)="cambioToken()"
        />
      </mat-form-field>

      <!-- Organization -->
      <mat-form-field *ngIf="form?.get('tipo')?.value === 'ChirpStack'">
        <mat-label>Organization</mat-label>
        <mat-select
          formControlName="organizationID"
          (selectionChange)="cambioOrganization()"
        >
          <mat-option
            *ngFor="let dato of organizationsChirpstack"
            [value]="dato.id"
          >
            {{ dato.displayName || dato.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- serviceProfileID -->
      <mat-form-field *ngIf="form?.get('tipo')?.value === 'ChirpStack'">
        <mat-label>Service Profile</mat-label>
        <mat-select formControlName="serviceProfileID">
          <mat-option
            *ngFor="let dato of serviceProfilesChirpstack"
            [value]="dato.id"
          >
            {{ dato.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Orbiwise -->
      <!-- User  -->
      <mat-form-field *ngIf="form?.get('tipo')?.value === 'Orbiwise'">
        <mat-label>User</mat-label>
        <input
          matInput
          placeholder="User Orbiwise"
          formControlName="user"
          (change)="cambioUser()"
        />
      </mat-form-field>

      <!-- Pass  -->
      <mat-form-field *ngIf="form?.get('tipo')?.value === 'Orbiwise'">
        <mat-label>Pass</mat-label>
        <input
          matInput
          placeholder="Pass Orbiwise"
          formControlName="pass"
          (change)="cambioPass()"
        />
      </mat-form-field>

      <!-- serviceProfileID -->
      <mat-form-field *ngIf="form?.get('tipo')?.value === 'Orbiwise'">
        <mat-label>Service Profile</mat-label>
        <mat-select formControlName="serviceProfileUUID">
          <mat-option
            *ngFor="let dato of serviceProfilesOrbiwise"
            [value]="dato.profile_uuid"
          >
            {{ dato.profile_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
